import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, Span } from "../components/Core";

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;

const Faq = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="9">
                <Title variant="hero">Frequently Asked Questions</Title>
                <Text>
                  Read how you can begin your journey to recovery.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey="general">
              <Row>
                <Col md="4" className="mb-5 mb-md-0">
                  <NavStyled className="flex-column mr-md-5">
                    <Nav.Link eventKey="general">General</Nav.Link>
                    <Nav.Link eventKey="medical condition">Medical conditions</Nav.Link>
                    <Nav.Link eventKey="pricing">Pricing</Nav.Link>
                    <Nav.Link eventKey="support">Support</Nav.Link>
                  </NavStyled>
                </Col>
                <Col md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="general">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                          What is Zahab Health?
                          </Title>
                          <Text variant="small">
                          Zahab Health is a health advisory company that helps patients from around the world find the right treatment in India
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                          How can Zahab Health help me with my treatment in India?
                          </Title>
                          <Text variant="small">
                          First, Zahab Health helps you connect with a partner clinic in your city. Once our partner doctors
                          understands your medical conditions, they'll identify whether a treatment in India is ideal for you. 
                          Then, we connect you with a doctor in India through video consultation to better understand your
                          condition. After that, we'll send you treatment plans from multiple leading hospitals in India. 
                          Our health advisors will help you choose. We'll also help you get a Visa, flight tickets, accomodation and everything
                          needed to visit India. After you undergo treatment in India, we'll also help you with post-surery
                          care by arranging follow-up consultations with the doctors.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                          <a href="/contact">
                            <Span color="primary">Contact us here</Span>
                          </a>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="medical condition">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            What kinds of conditions are ideal for treatment in India?
                          </Title>
                          <Text variant="small">
                            Any medical condition that requires specialised skills are recommended in India. For example, doctors in India are especially skillful at ortho(knees, shoulders), spine, oncology(cancer) and cardiology(heart).
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            I'm already undergoing treatment. Can you still help?
                          </Title>
                          <Text variant="small">
                            Yes. Even if you are undergoing treatment in your local hospital, 
                            you can get in touch us with for a second opinion. We have worked with
                            several patients who got a better treatment when they worked with us.
                          </Text>
                        </Box>

                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                          <a href="/contact">
                            <Span color="primary">Contact us here</Span>
                          </a>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pricing">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How much does it cost to visit India?
                          </Title>
                          <Text variant="small">
                            It depends. Treatment in Indian hospitals are usually 70% cheaper than other countries. Once you send us 
                            your past medical reports, we will send you treatment plans including pricing from the leading hospitals, for absolutely
                            free.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How much does Zahab cost?
                          </Title>
                          <Text variant="small">
                            Zahab does not charge the patients anything. We are independent service partners with the hospitals and are compensated by
                            the hospitals. Since we work independently, we are able to get the you the best
                            treatment plan from across hospitals.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            When should I make the payment?
                          </Title>
                          <Text variant="small">
                            You don't have to make any payments to the hospital or to us before visiting the hospital.
                            You need to pay only after reaching the hospital and consulting with the doctors.
                          </Text>
                        </Box>

                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                          <a href="/contact">
                            <Span color="primary">Contact us here</Span>
                          </a>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="support">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How will Zahab support me?
                          </Title>
                          <Text variant="small">
                            Zahab will be your health and travel guide throughout. We help you connect with doctors
                            and other professionals when you need them. We support you in booking the flight, getting
                            medical visa, arranging transportation, accomodation, and everything in between.
                          </Text>
                        </Box>

                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                          <a href="/contact">
                            <Span color="primary">Contact us here</Span>
                          </a>
                        </Box>
                      </Box>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Faq;
